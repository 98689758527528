import { Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import AlertDialog from "../../components/atoms/Popup";
import { ModifiedProcess } from "../../contexts/RemoteControlState";
import { Patient } from "../../data/Patient";
import { translate } from "../../text/translator";
import Patients from "../users/Patients";
import RemoteConnections from "./connection/RemoteConnections";
import Processes from "./processes/Processes";
import QuickActions from "./quickActions/QuickActions";
import { RemoteControlContext } from "./RemoteControl.context";
import RemoteControlBar from "./RemoteControlBar";
import { RemoteControlTag, toRemoteControlMessage } from "./remoteControlMessage";
import PatientSettings from "./settings/PatientSettings";
import { UserContext } from "../../contexts/User.context";
import { SessionNotifier } from "./SessionNotifier";


function RemoteControl() {

	const remoteControl = useContext(RemoteControlContext);

	const [settingsModalOpen, setSettingsModalOpen] = useState(false);
	const [shouldNavigate, setShouldNavigate] = useState(true);

	const navigate = useNavigate();

	const user = useContext(UserContext);

	const handleOpenSettingsModal = () => {
		setSettingsModalOpen(true);
	};

	const handleCloseSettingsModal = () => {
		remoteControl.commitPatientSettings();
		setSettingsModalOpen(false);
	};

	useEffect(() => {
		const { remoteClient, patient, processes } = remoteControl.state;
		if (!patient) {
			setSettingsModalOpen(false);
			navigate("./patients");
		} else if (shouldNavigate && !remoteClient) {
			navigate("./devices");
		} else if (shouldNavigate && processes.length === 0) {
			navigate("./processes");
		} else if (shouldNavigate) {
			navigate("./quickactions");
		}

		return () => {
			setShouldNavigate(false);
		};
	}, [navigate, shouldNavigate, remoteControl.state]);

	const handlePatientSelected = (patient: Patient) => {
		remoteControl.setRemoteUser(patient.userID ?? user?.uid!, patient);
		navigate("./devices");
	}

	useEffect(() => {
		const handleClientConnected = () => {
			navigate("./processes");
		};
		remoteControl.eventTarget.addEventListener('clientConnected', handleClientConnected);
		return () => {
			remoteControl.eventTarget.removeEventListener('clientConnected', handleClientConnected);
		};
	}, [navigate, remoteControl.eventTarget]);

	const handleProcessRequired = () => {
		navigate("./processes");
	}

	const handleProcessSelected = (processes: ModifiedProcess[]) => {
		remoteControl.setProcesses(processes);

		if (!!processes) {
			const trainingAddresses = processes.map((process) => {
				return {
					training_processID: process.process.id,
					...process.modifiers
				};
			});

			const message = toRemoteControlMessage(
				RemoteControlTag.trainingRequested,
				JSON.stringify(trainingAddresses));
			remoteControl.sendMessage(message);
		}

		navigate("./quickactions");
	}

	const handleRouteChanged = (route: string) => {
		navigate(route);
	}

	return (
		<>
			<Typography variant="h3">
				{translate("[remotecontrol_title]")}
			</Typography>
			<RemoteControlBar
				state={remoteControl.state}
				onPatientConfig={handleOpenSettingsModal}
				onDisconnect={remoteControl.disconnect}
				onRouteChange={handleRouteChanged} />
			<br />

			<SessionNotifier patientID={
				remoteControl.state.patient?.id === "self" ?
				remoteControl.state.patient?.userID : remoteControl.state.patient?.id} />

			<div>
				<Routes>
					<Route path="/patients" element={
						<>
							<Typography variant="h5">
								{translate("[remotecontrol_patients]")}
							</Typography>
							<Patients onPatientSelected={handlePatientSelected} />
						</>
					} />

					<Route path="/devices" element={
						<>
							<Typography variant="h5">
								{translate("[remoteconnections_desc]")}
							</Typography>
							<RemoteConnections
								userID={remoteControl.userID}
								clients={remoteControl.clients}
								onConnect={remoteControl.connectToClient}
								onRefresh={remoteControl.refresh} />
						</>
					} />

					<Route path="/processes" element={
						<>
							<Typography variant="h5">
								{translate("[remotecontrol_processes]")}
							</Typography>
							<Processes onProcessesSelected={handleProcessSelected}
								patient={remoteControl.state.patient}
								minVersion={remoteControl.state.remoteClient?.version} />
						</>
					} />

					<Route path="/quickactions" element={
						<>
							<Typography variant="h5">
								{translate("[remotecontrol_quickactions]")}
							</Typography>
							{remoteControl.state.remoteClient ? (
								<QuickActions
									sendMessage={remoteControl.sendMessage}
									training={remoteControl.currentTraining}
									requestTraining={handleProcessRequired}
									stream={remoteControl.stream!} />
							) : (
								<CircularProgress />
							)}
						</>
					} />
				</Routes>
			</div>

			{!!remoteControl.state.patient &&
				<AlertDialog
					open={!!settingsModalOpen}
					onCancel={handleCloseSettingsModal}
					title={translate("[page_settings]")}>
					<PatientSettings
						data={remoteControl.state.patient.settings}
						sendMessage={remoteControl.sendMessage}
						onSettingsUpdated={remoteControl.setPatientSettings}
					/>
				</AlertDialog>
			}
		</>
	)
}

export default RemoteControl;