import { FirebaseApp } from 'firebase/app';
import { httpsCallable, getFunctions, Functions, HttpsCallable } from 'firebase/functions';

let firebaseFunctions: Functions;
let fbCallableFunctions: Record<string, HttpsCallable<unknown, any>>;

const region = "europe-west1";

export function InitializeFBFunctions(app: FirebaseApp) {
	firebaseFunctions = getFunctions(app, region);

	fbCallableFunctions = {
		addUnmanagedPatientCall: httpsCallable(firebaseFunctions, "addPatientEU"),
		deletePatient: httpsCallable(firebaseFunctions, "deletePatientEU"),
		renamePatient: httpsCallable(firebaseFunctions, "renamePatient"),
		listAllSessions: httpsCallable(firebaseFunctions, "listAllSessions"),
		getUsageData: httpsCallable(firebaseFunctions, "getUsageData"),
		deleteSessions: httpsCallable(firebaseFunctions, "deleteSessions"),

		inviteTherapistToCentre: httpsCallable(firebaseFunctions, "inviteTherapistToCentre"),
		acceptCentreInvitation: httpsCallable(firebaseFunctions, "acceptCentreInvitation"),
		rejectCentreInvitation: httpsCallable(firebaseFunctions, "rejectCentreInvitation"),
		cancelTherapistInvitation: httpsCallable(firebaseFunctions, "cancelTherapistInvitation"),

		invitePatientToTherapist: httpsCallable(firebaseFunctions, "invitePatientToTherapist"),
		acceptPatientInvitation: httpsCallable(firebaseFunctions, "acceptPatientInvitation"),
		rejectPatientInvitation: httpsCallable(firebaseFunctions, "rejectPatientInvitation"),
		cancelPatientInvitation: httpsCallable(firebaseFunctions, "cancelPatientInvitation"),

		askAI: httpsCallable(firebaseFunctions, "askAIEU"),
		sendEmail: httpsCallable(firebaseFunctions, "sendMailEU"),


		stripePortal: httpsCallable(firebaseFunctions, "ext-firestore-stripe-payments-createPortalLink"),
	}
}

/**
 * Request FBFunction 'addPatient' in order to add a patient to the current user
 * 
 * @param userId the user to handle the patient
 * @returns the new patient from FB firestore
 */
export async function addUnmanagedPatient(userID: any, initials?: string) {
	const result = await fbCallableFunctions.addUnmanagedPatientCall({
		userID: userID,
		initials: initials ?? "",
	});

	const code: number = result.data.result;
	return code;
}

/**
 * Request FBFunction 'deletePatient' in order to delete a patient from an ID
 * 
 * @param patientID id of the patient to be deleted
 * @param userID id of the patient user
 */
export async function deletePatient(patientID: string, userID: string) {
	const result = await fbCallableFunctions.deletePatient({ userID, patientID });
	const code = result.data.result;
	return code === 200;
}

export async function renamePatient(patientID: string, initials: string) {
	const result = await fbCallableFunctions.renamePatient({ patientID, initials });
	const code = result.data.result;
	return code === 200;
}

export async function listAllSessions(userEmail: string, limitDays: number, limitAmount: number): Promise<{ result: number, data: any }> {
	const result = await fbCallableFunctions.listAllSessions({ userEmail, limitAmount, limitDays });
	return result.data;
}

export async function deleteSessions(sessionIDs: string[]): Promise<{ result: number, data: any }> {
	const result = await fbCallableFunctions.deleteSessions({ sessionIDs });
	return result.data;
}

export async function getUsageData(userEmail: string): Promise<{ result: number, data: any }> {
	const result = await fbCallableFunctions.getUsageData({ userEmail });
	return result.data;
}

/**
 * Request FBFunction 'inviteTherapistToCentre' in order to invite a therapist to the current user's center
 * 
 * @param therapistID the therapist to invite
 */
export async function inviteTherapistToCentre(therapistEmail: string) {
	const result = await fbCallableFunctions.inviteTherapistToCentre({ therapistEmail });
	return result.data;
}

/**
 * Request FBFunction 'acceptCentreInvitation' in order to accept an invitation to a center
 * 
 * @param invitationID the invitation to accept
 */
export async function acceptCentreInvitation(invitationID: string) {
	const result = await fbCallableFunctions.acceptCentreInvitation({ invitationID });
	return result.data;
}

/**
 * Request FBFunction 'rejectCentreInvitation' in order to reject an invitation to a center
 * 
 * @param invitationID the invitation to reject
 */
export async function rejectCentreInvitation(invitationID: string) {
	const result = await fbCallableFunctions.rejectCentreInvitation({ invitationID });
	return result.data;
}

/**
 * Request FBFunction 'cancelTherapistInvitation' in order to cancel an invitation to a center
 * 
 * @param invitationID the invitation to cancel
 */
export async function cancelTherapistInvitation(invitationID: string) {
	const result = await fbCallableFunctions.cancelTherapistInvitation({ invitationID });
	return result.data;
}


/**
 * Request FBFunction 'invitePatientToTherapist' in order to invite a patient to a therapist
 * 
 * @param patientEmail the patient to invite
 */
export async function invitePatientToTherapist(patientEmail: string) {
	const result = await fbCallableFunctions.invitePatientToTherapist({ patientEmail });
	return result.data;
}

/**
 * Request FBFunction 'acceptPatientInvitation' in order to accept an invitation to a therapist
 * 
 * @param invitationID the invitation to accept
 */
export async function acceptPatientInvitation(invitationID: string) {
	const result = await fbCallableFunctions.acceptPatientInvitation({ invitationID });
	return result.data;
}

/**
 * Request FBFunction 'rejectPatientInvitation' in order to reject an invitation to a therapist
 * 
 * @param invitationID the invitation to reject
 */
export async function rejectPatientInvitation(invitationID: string) {
	const result = await fbCallableFunctions.rejectPatientInvitation({ invitationID });
	return result.data;
}

/**
 * Request FBFunction 'cancelPatientInvitation' in order to cancel an invitation to a therapist
 * 
 * @param invitationID the invitation to cancel
 */
export async function cancelPatientInvitation(invitationID: string) {
	const result = await fbCallableFunctions.cancelPatientInvitation({ invitationID });
	return result.data;
}

/**
 * Ask GPT
 * 
 * @param systemPrompt prompt for the AI describing itself
 * @param userPrompt actual request from the user
 */
export async function askAI(systemPrompt: string, userPrompt: string) {
	const result = await fbCallableFunctions.askAI({ systemPrompt, userPrompt });
	const answer = result.data.data;
	return answer;
}
/**
 * Send Email
 * 
 * @param message contact message
 */
export async function sendEmail(subject: string, sender: string, message: string): Promise<boolean> {
	const result = await fbCallableFunctions.sendEmail({ subject, sender, message });
	const code = result.data.result;
	return code === 200;
}

export function getStripePortal() {
	return fbCallableFunctions.stripePortal;
}