import { useState } from "react";
import { Box, Button, CircularProgress, Paper, TextField, Typography } from "@mui/material";
import { translate } from "../../text/translator";
import UserSessions from "./UserSessions";
import { CachedDocument, listAllSessions, getUsageData } from "../../adapters/Firebase";
import LastUsageSessionsChart from "./LastUsageSessionsChart";
import SessionsPieChart from "./SessionsPieChart";
import ToggleGroupControl from "../remoteControl/controls/ToggleGroupControl";
import SearchIcon from '@mui/icons-material/Search';


async function getAllUserSessions({ userEmail, dateLimit, countLimit }:
	{ userEmail: string, dateLimit: number, countLimit: number })
	: Promise<any> {
	const response = await listAllSessions(userEmail, dateLimit, countLimit);
	if (response.result !== 200) {
		throw new Error(`Error fetching user sessions: ${response.result}`);
	}
	return response.data;
}
async function getUserMetaData({ userEmail }:
	{ userEmail: string })
	: Promise<any> {
	const response = await getUsageData(userEmail);
	if (response.result !== 200) {
		throw new Error(`Error fetching user meta data: ${response.result}`);
	}
	return response.data;
}

function AdminTools() {
	const [dateLimit, setDateLimit] = useState<number>(0);
	const [metaData, setMetaData] = useState<any>({});
	const [sessions, setSessions] = useState<CachedDocument[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const dateLimitDays = [31, 92, 366];

	function handleSearch(event: any) {

		setIsLoading(true);
		event.preventDefault();
		const { email, amount } = event.target.elements;
		if (!email.checkValidity()
			|| !amount.checkValidity) {
			return;
		}

		const searchParams = {
			userEmail: email.value,
			dateLimit: dateLimitDays[dateLimit],
			countLimit: Number(amount.value)
		};

		setMetaData({});
		setSessions([]);

		getUserMetaData({ userEmail: email.value })
			.then((data) => {
				setMetaData(() => data);
			})
			.catch((error) => {
				console.error(error);
			});

		getAllUserSessions(searchParams)
			.then((sessions) => {
				setSessions(() => sessions);
				setIsLoading(() => false);
			})
			.catch((error) => {
				console.error(error);
			});
	}

	return (
		<>
			<Typography variant="h3">
				{translate("[admin_title]")}
			</Typography>
			<Typography variant="body1" gutterBottom>
				{translate("[admin_description]")}
			</Typography>

			<Box display="flex" gap={2} marginTop={4} justifyContent="space-between" alignItems="center"
				flexWrap="wrap"
				component="form"
				onSubmit={handleSearch}>

				<TextField
					color="primary"
					type="email" inputMode="email" name="email"
					label={translate("[admin_search_email]")}
					required
				/>
				<TextField
					color="primary"
					type="number" inputMode="numeric" name="amount"
					label={translate("[admin_search_amount]")}
					required
					defaultValue={100}
				/>

				<ToggleGroupControl
					items={["[admin_datelimit_1month]", "[admin_datelimit_3month]", "[admin_datelimit_1year]"]}
					value={dateLimit}
					onValueChanged={(value: number) => {
						if (value >= 0) {
							setDateLimit(value);
						}
					}} />

				<Button
					type="submit"
					variant="contained"
					sx={{ marginLeft: 'auto' }}
					startIcon={<SearchIcon />}>
					{translate("[admin_search_action]")}
				</Button>
			</Box>

			<br />

			{isLoading &&
				<Box display="flex" justifyContent="center">
					<CircularProgress />
				</Box>
			}
			{!isLoading &&
				<>
					<Typography variant="h5" gutterBottom>
						{translate("[admin_metadata]")}
					</Typography>

					<Paper elevation={3} sx={{ padding: 2 }}>

						<Box display="flex" flexDirection="row" gap={2} flexWrap="wrap">
							<Typography variant="h6">
								{translate("[admin_metadata_overriderole]:")}
							</Typography>
							<Typography variant="body1">
								{translate(`[role_${metaData?.claims?.overrideRole}]`)}
							</Typography>
							<Typography variant="h6">
								{translate("[admin_metadata_stripeRole]:")}
							</Typography>
							<Typography variant="body1">
								{translate(`[role_${metaData?.claims?.stripeRole}]`)}
							</Typography>
							<Typography variant="h6">
								{translate("[admin_metadata_admin]:")}
							</Typography>
							<Typography variant="body1">
								{!!metaData?.claims?.admin ? "☑️" : "☐"}
							</Typography>
						</Box>

						<Box display="flex" flexDirection="row" gap={2} flexWrap="wrap">
							<Typography variant="h6">
								{translate("[admin_metadata_vrlogins]:")}
							</Typography>
							<Typography variant="body1">
								{metaData?.vrLogins}
							</Typography>
							<Typography variant="h6">
								{translate("[admin_metadata_vrdate]:")}
							</Typography>
							<Typography variant="body1">
								{!!(metaData?.vrDate) ?
									new Date(metaData.vrDate._seconds * 1000).toLocaleString()
									: ""}
							</Typography>
							<Typography variant="h6">
								{translate("[admin_metadata_vrversion]:")}
							</Typography>
							<Typography variant="body1">
								{metaData?.vrVersion}
							</Typography>
						</Box>

						<Box display="flex" flexDirection="row" gap={2} flexWrap="wrap">
							<Typography variant="h6">
								{translate("[admin_metadata_patientCount]:")}
							</Typography>
							<Typography variant="body1">
								{metaData?.patientCount}
							</Typography>
							<Typography variant="h6">
								{translate("[admin_metadata_sessionsCount]:")}
							</Typography>
							<Typography variant="body1">
								{sessions.length}
							</Typography>
						</Box>
					</Paper>
					<br />

					<Typography variant="h5" gutterBottom>
						{translate("[admin_lastsessionschart]")}
					</Typography>
					<LastUsageSessionsChart sessions={sessions} daysLimit={dateLimitDays[dateLimit]} />

					<Typography variant="h5" gutterBottom>
						{translate("[admin_sessionspiechart]")}
					</Typography>
					<SessionsPieChart sessions={sessions} />

					<Typography variant="h5" gutterBottom>
						{translate("[admin_usersessions]")}
					</Typography>
					<UserSessions
						sessions={sessions}
						isLoading={isLoading} />
				</>
			}
		</>
	);

}

export default AdminTools;
