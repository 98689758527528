import Snackbar from '@mui/material/Snackbar';
import { collection, getFirestore, onSnapshot, orderBy, query, Timestamp, Unsubscribe, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { translate } from '../../text/translator';
import { Button } from '@mui/material';

function subscribeToNewSession(patientID: string, onNewSession: (session: string) => void): Unsubscribe {
    const firestore = getFirestore();
    const collectionName = "mockSessions";
    const firestoreTimestamp = Timestamp.fromMillis(Date.now());

    const queryConstraints = [
        where("userID", "==", patientID),
        where("timestamp", ">", firestoreTimestamp),
        orderBy("timestamp", "desc"),
    ];

    const cachedQuery = query(collection(firestore, collectionName), ...queryConstraints);
    return onSnapshot(cachedQuery, (snapshot) => {
        snapshot.docChanges().forEach(change => {
            if (change.type === "added") {
                onNewSession(change.doc.id);
            }
        });
    });
}


interface SessionNotifierProps {
    patientID?: string;
}

export function SessionNotifier(props: SessionNotifierProps) {

    const navigate = useNavigate();
    const [sessionID, setSessionID] = useState<string | null>(null);

    function handleAction() {
        if (!!sessionID) {
            navigate(`/sessions/patient/${sessionID}`);
            setSessionID(null);
        }
    };

    useEffect(() => {
        if (!props.patientID) {
            return;
        }

        return subscribeToNewSession(props.patientID, (sessionID) => {
            setSessionID(sessionID);
        });
    }, [props.patientID]);

    function handleClose() {
        setSessionID(null);
    }

    return (<Snackbar
        color="background.paper"
        open={!!sessionID}
        autoHideDuration={15 * 1000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
            '& .MuiSnackbarContent-root': {
              backgroundColor: "background.paper",
              color: "inherit",
            },
          }}
        onClose={handleClose}
        message={translate("[remotecontrol_notification_body]")}
        action={
            <Button color="primary" size="small" onClick={handleAction}>
                {translate("[remotecontrol_notification_action]")}
            </Button>
        }/>
    )
}