import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { DataGrid, GridColDef, GridComparatorFn, GridRenderCellParams, GridRowId, GridRowParams, GridRowsProp, GridSelectionModel } from '@mui/x-data-grid';
import { memo, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import ProcessPill from '../../components/atoms/ProcessPill';
import { ModifiersText } from "./Modifiers";
import { useSessions } from "./useSessions";
import { CachedDocument, deleteSessions } from "../../adapters/Firebase";
import { Typography } from '@mui/material';
import { translate } from '../../text/translator';
import AlertDialog from '../../components/atoms/Popup';

interface SessionListProps {
	patientID: string
}

interface ModifiersData {
	modifiers: any,
	processID: string,
}

function SessionList(props: SessionListProps) {
	const defaultPageCount = 10;
	const [selectedSessions, setSelectedSessions] = useState<GridSelectionModel>([])
	const [maxSessions, setmaxSessions] = useState<number>(defaultPageCount)
	const { sessions, isLoading } = useSessions({ patientID: props.patientID, limit: maxSessions });
	const [displayedSessions, setDisplayedSessions] = useState<CachedDocument[]>([]);
	const [deleting, setDeleting] = useState(false);

	useEffect(() => {
		if (sessions) {
			setDisplayedSessions(sessions);
		}
	}, [sessions]);

	function handleSelectedRowsChange(selectionModel: GridSelectionModel) {
		setSelectedSessions(selectionModel);
	}

	const navigate = useNavigate();
	const getDate = (date: Date) => new Intl.DateTimeFormat('es-es', { dateStyle: 'medium', timeStyle: 'short' }).format(date);

	const processComparator: GridComparatorFn<string> = (v1, v2) => {
		const process1: string = v1!;
		const process2: string = v2!;
		return process1.localeCompare(process2);
	}

	function sessionDataToRow(session: CachedDocument) {
		return {
			id: session.id,
			processID: session.doc.processID,
			modifiers: { modifiers: session.doc.modifiers, processID: session.doc.processID },
			date: getDate(new Date(session.doc.timestamp.seconds * 1000)),
		};
	};

	function rowClicked(params: GridRowParams) {
		navigate(`./${params.id}`);
	}

	function buttonClicked() {
		const params = selectedSessions.reduce(
			(acum: string, id: GridRowId) => {
				return `${acum}&${id.valueOf()}`;
			}, "");

		navigate(`./${params}`);
	}

	function deleteSelectedSessions() {
		const toDeleteSessions = selectedSessions.map((id: GridRowId) => id.toString());
		setSelectedSessions([]);
		setDeleting(false)
		setDisplayedSessions(displayedSessions.filter((session: CachedDocument) => !toDeleteSessions.includes(session.id)));
		deleteSessions(toDeleteSessions);
	}

	const columns: GridColDef[] = [
		{
			field: "processID", headerName: translate("[session_process]"), width: 190, sortable: true,
			renderCell: (params: GridRenderCellParams<string>) => {
				const processID: string = params.value!;
				return <ProcessPill processID={processID} />
			},
			sortComparator: processComparator
		},
		{ field: "date", headerName: translate("[session_date]"), type: 'date', width: 160, sortable: true },
		{
			field: "modifiers", headerName: translate("[session_modifiers]"), flex: 1, sortable: false,
			renderCell: (params: GridRenderCellParams<ModifiersData>) => {
				return <ModifiersText
					modifiers={params.value?.modifiers}
					processID={params.value?.processID!} />
			},
		},
	];

	const rows: GridRowsProp = displayedSessions.map((session: any) => sessionDataToRow(session));

	return (<>
		<div style={{ textAlign: "right" }}>
			<Button
				onClick={buttonClicked}
				variant="outlined"
				disabled={selectedSessions.length === 0}>
				{translate("[sessionlist_comparer]")}
			</Button>
		</div>
		<br />
		<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
			<div style={{ display: 'flex', height: '100%' }}>
				<div style={{ flexGrow: 1 }}>
					<DataGrid
						checkboxSelection={true}
						onSelectionModelChange={handleSelectedRowsChange}
						autoHeight {...sessions}
						onRowClick={rowClicked}
						rows={rows}
						columns={columns}
						loading={isLoading}
						pageSize={defaultPageCount}
						rowsPerPageOptions={[defaultPageCount]}
					/>
				</div>
			</div>
		</Paper>
		<div style={{ textAlign: "right" }}>
			<br />
			<Button
				onClick={()=>setDeleting(true)}
				disabled={selectedSessions.length == 0}>
				{translate("[sessionlist_deleteall]")}
			</Button>
			{maxSessions > 0 &&
				<>
					<br />
					<Typography variant="caption" >
						{translate("[sessionlist_loadmore]")}
					</Typography>

					<Button
						onClick={() => setmaxSessions(-1)}
						disabled={maxSessions <= 0}>
						{translate("[sessionlist_loadall]")}
					</Button>
				</>
			}
		</div>

		< AlertDialog
				open={deleting}
				title={`${translate("[sessions_delete]")} ${selectedSessions.length}`}
				okTitle={translate("[sessions_deleteaccept]")}
				cancelTitle={translate("[sessions_deletecancel]")}
				onOk={deleteSelectedSessions}
				onCancel={()=> setDeleting(false)}>
				{translate("[sessions_confirmdelete]")}
			</AlertDialog>
	</>
	);
}

export default memo(SessionList,
	(prevProps, nextProps) => {
		return prevProps.patientID === nextProps.patientID;
	}
);
